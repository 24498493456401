<template>
  <v-col cols="12">
    <v-timeline class="gw-release" v-if="items.length">
      <template v-for="obRelease in items">
        <v-timeline-item :key="`release.${obRelease.id}`" fill-dot right>
          <template #opposite>
            <div class="text-h5" v-text="obRelease.version" />
          </template>

          <template #icon>
            <v-btn
              :class="{ active: arDisplayItems.includes(obRelease.id) }"
              dark
              icon
              @click="onToggle(obRelease)"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>

          <v-card color="transparent" flat>
            <v-card-title
              class="text-h6 text-left primary--text"
              v-text="obRelease.title"
            />
            <v-card-text
              v-if="obRelease.description.length"
              class="white"
              v-text="obRelease.description"
            />
          </v-card>
        </v-timeline-item>

        <collapse-transition :key="`release.notes.${obRelease.id}`">
          <div v-if="arDisplayItems.includes(obRelease.id)">
            <template v-for="obNote in obRelease.notes">
              <release-note-item
                :key="`release.note.${obNote.id}`"
                :item="obNote"
              />
            </template>
          </div>
        </collapse-transition>
      </template>
    </v-timeline>
  </v-col>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import ReleasesMixin from "@/modules/releases/mixins/ReleasesMixin";
import type { ReleaseData } from "@planetadeleste/vue-mc-gw";
import ReleaseNoteItem from "@/modules/releases/components/ReleaseNoteItem.vue";
import { CollapseTransition } from "vue2-transitions";
import { get, indexOf } from "lodash";

@Component({
  components: { ReleaseNoteItem, CollapseTransition },
})
export default class ReleasesList extends Mixins(ReleasesMixin) {
  isList = true;
  arDisplayItems: number[] = [];

  get items(): ReleaseData[] {
    return !!this.obCollection && this.obCollection.length
      ? (this.obCollection.getModelList() as ReleaseData[])
      : [];
  }

  onToggle(obItem: ReleaseData) {
    const sId = get<ReleaseData, keyof ReleaseData>(obItem, "id") as
      | number
      | undefined;

    if (!sId) {
      return;
    }

    if (!this.arDisplayItems.includes(sId)) {
      this.arDisplayItems.push(sId);
    } else {
      const sIndex = indexOf(this.arDisplayItems, sId);
      if (sIndex !== -1) {
        this.arDisplayItems.splice(sIndex, 1);
      }
    }
  }

  async onMounted() {
    await this.index();
    if (this.items.length) {
      this.onToggle(this.items[0]);
    }
  }
}
</script>
