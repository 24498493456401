import { Mixins, Component } from "vue-property-decorator";
import ModelMixin from "@/mixins/ModelMixin";
import type { VueClass } from "vue-class-component/lib/declarations";
import { Release, ReleaseCollection } from "@planetadeleste/vue-mc-gw";

const TypedModelMixin = ModelMixin as unknown as VueClass<
  ModelMixin<Release, ReleaseCollection>
>;

@Component
export default class ReleasesMixin extends Mixins(TypedModelMixin) {
  obCollection = new ReleaseCollection();
  obModelClass = Release;
  obCollectionClass = ReleaseCollection;
  sRoutePath = "/releases";

  created() {
    this.onCreated();
  }
}
